@font-face {
  font-family: 'Marlboro';
  src: url('./fonts/marlboro.regular.ttf');
}

@font-face {
  font-family: 'Wildwest';
  src: url('./fonts/Wildwest.ttf');
}

@font-face {
  font-family: 'InsaneRodeo';
  src: url('./fonts/insane-rodeo.ttf');
}

body {
  background-color: #282c34;
  margin: 0;
  padding: 0 3vmin;

  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: white;
}

body, button {
  font-size: calc(10px + 3vmin);
  line-height: calc(10px + 6vmin);
}

button {
  padding: 0.3em 1.2em;
  border-radius: 12px;
  border-style: solid;
  border-width: 3px;
  border-color: white #999 black #999;
  vertical-align: center;
  box-shadow: 0 5px 10px #00000099;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 30px #ffffff44;
  }
}

h1, h2, h3, h4, h5 {
  font-weight: normal;
}

h1 {
  font-family: 'InsaneRodeo', 'Times New Roman', serif;
  font-size: calc(10px + 12vmin);
  line-height: calc(10px + 10vmin);
  span.escape {
    font-family: 'Times New Roman', serif;
    font-size: 0.7em;
    vertical-align: top;
  }
}
h2 {
  font-size: calc(10px + 5vmin);
  margin: calc(5px + 2vmin);
  line-height: calc(5px + 8vmin);
}
h3 {
  font-size: calc(10px + 4vmin);
  margin: calc(5px + 1vmin);
}