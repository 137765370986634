@import '../shared';

.Home {

  h2, footer, .charges {
    font-family: 'Marlboro', 'Times New Roman', serif;
  }

  text-align: center;

  display: flex;
  flex-direction: column;
  gap: 2vh;

  img.hero {
    width: 90vmin;
    pointer-events: none;
  }

  header {
    h1 {
      text-shadow: 0 1vmin 0 black;
    }
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .MDS {
    padding: $spacing-large $spacing-large $spacing-medium $spacing-large;
    background-color: $color-background-light;
    color: $color-text-dark;

    strong.unhinged {
      display: inline-block;
      color: $color-bad;
      rotate: -4deg;
      margin: 0 0.1em;
    }

    strong {
      text-shadow: 0 1px 0 white;
    }

    border-radius: 2vmin;

    h1 {
      color: $color-accent;
      text-shadow: -3px 0 white, 0 3px white, 3px 0 white, 0 -3px white, 0 0 20px #00000022;
      margin: 10px 0 0 0;
    }

    .tweet-list {
      display: flex;
      flex-wrap: wrap;
      flex-direction: row;
      justify-content: center;
      gap: $spacing-medium;
      text-align: left;

      .tweet {
        font-family: TwitterChirp, -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, Helvetica, Arial, sans-serif;
        background-color: white;
        border-radius: 10px;
        padding: $spacing-medium;
        display: flex;
        flex-direction: column;
        gap: $spacing-small;

        .author, .meta, .content {
          gap: $spacing-small;
          display: flex;
          align-items: center;
        }
        .meta {
          justify-content: flex-end;
        }
        .author, .meta {
          font-size: 0.9em;
          color: #666;
        }

        img.avatar {
          width: $spacing-large;
          height: $spacing-large;
        }
      }
    }
  }

  .joinUs {
    button {
      margin: 3vmin 0;
    }
  }

  footer {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 1em;
    text-shadow: 0 0.1em 0 black;
  }

  a:link, a:visited {
    text-decoration: none;
    color: $color-link-light;
  }
  a:hover, a:active {
    color: lighten($color-link-light, 10%);
  }
}

hr {
  margin: $spacing-medium 0
}

p {
  margin-left: 10vmin;
  margin-right: 10vmin;
}

@media screen and (max-width: 700px) {
  p {
    margin-left: 0;
    margin-right: 0;
  }

  .tweet-list {
    padding: 0;
    margin: 0 -20px;
    display: flex;
    flex-wrap: wrap;
    flex-direction: row;
    justify-content: center;
    gap: 3vmin;
  }
}






