$color-accent: #a46440;
$color-bad: #7f3131;
$color-background-dark: #282c34;
$color-background-light: lighten($color-background-dark, 75%);
$color-text-dark: #282c34;
$color-link-light: #8CF;

$spacing-small: 2vmin;
$spacing-medium: 4vmin;
$spacing-large: 7vmin;

.bad {
  color: $color-bad;
}